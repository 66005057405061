import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import testimg from "../../../Assets/myspacetest.png";
import testtime from "../../../Assets/testtime.png";
import testmark from "../../../Assets/testmarks.png";
import testQues from "../../../Assets/testques.png";
import { api_call_token } from "../../../Utils/Network";
import { useHistory, useParams, useLocation } from "react-router-dom";
import notest from "../../../Assets/no-test.png";

function McqPage({ onTakeTest, setSelectCourse }) {
  const [loading, setLoading] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [page, setPage] = useState(1);
  const [lastElement, setLastElement] = useState(null);
  const [maxpage, setmaxPage] = useState(1);
  const { id, ppt, courseId } = useParams();
  const history = useHistory();

  const observer = useRef(
    typeof window != "undefined" &&
      new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting && page <= maxpage) {
          setPage((no) => no + 1);
        }
      })
  );
  console.log(courseId, "mcqpageCourseId");
  useEffect(() => {
    if (page <= maxpage) {
      getMcqlist();
    }
  }, [page]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentObserver == false) return;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  const getMcqlist = () => {
    console.log("Fetching page", page);
    api_call_token
      .get(
        `content/V1/course/listing/?course__id=${courseId}&questions__question_type=1&quiz_type=1&page=${page}`
      )
      .then((res) => {
        // setQuestionList(res?.data?.data);
        // console.log(res?.data?.data, "1234");
        const maxpage = res?.data?.max_pages;
        setmaxPage(maxpage);

        const datanew = res?.data?.data;
        console.log(res?.data?.course?.title, "1234");
        setQuestionList((prevState) => {
          const updatedState = [...prevState, ...datanew];
          return updatedState;
        });
        setSelectCourse(res?.data?.course?.title)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   getMcqlist();
  // }, []);

  const onViewResult = (v) => {
    history.push(`/my-space/1/${courseId}/mcq/${v?.id}/result`);
    // /my-space/:tabId/:ppt/:id/result
    console.log(v, "vDetails");
  };

  return (
    <div className={styles.mainContainer}>
      {questionList?.length === 0 && (
        <div
          className={styles.nodataAvl}
          style={{ margin: "0 auto", width: "fit-content" }}
        >
          <img src={notest} alt="no Video" />
          <p style={{ fontSize: "20px", textAlign: "center" }}>No Test </p>
        </div>
      )}
      {questionList?.length > 0 && (
        <>
          <h2 className={styles.CourseHeading}>{questionList[0]?.course}</h2>
          {/* <h3 className={styles.heading}>MCQ</h3> */}
          {questionList?.map((v) => {
            return (
              <div className={styles.card} key={v?.id} ref={setLastElement}>
                <div className={styles.leftContainer}>
                  <div className={styles.imageContainer}>
                    {/* <img src={testimg} /> */}
                    <img src={v?.image &&
                                          (v?.image.endsWith(".jpg") ||
                                            v?.image.endsWith(".png"))
                                            ? v?.image
                                            : testimg} />
                  </div>
                  <h3 className={styles.title}>{v?.title}</h3>
                </div>

                <div className={styles.questionDetails}>
                  <div className={styles.imgtext}>
                    <div className={styles.infoimg}>
                      <img src={testmark} />
                    </div>
                    <div className={styles.text}>
                      <h4>{v?.marks}</h4>
                      <p>Max. Marks</p>
                    </div>
                  </div>
                  <div className={styles.imgtext}>
                    <div className={styles.infoimg}>
                      <img src={testQues} />
                    </div>
                    <div className={styles.text}>
                      <h4>{v?.total_question}</h4>
                      <p>Total Ques.</p>
                    </div>
                  </div>
                  <div className={styles.imgtext}>
                    <div className={styles.infoimg}>
                      <img src={testtime} />
                    </div>
                    <div className={styles.text}>
                      <h4>{v?.time}</h4>
                      <p>Total Time</p>
                    </div>
                  </div>
                </div>
                {v?.test_submited ? (
                  <button
                    className={styles.teketestbtn}
                    onClick={() => onViewResult(v)}
                  >
                    View Result
                  </button>
                ) : (
                  <button
                    className={styles.teketestbtn}
                    onClick={() => {
                      onTakeTest(v);
                    }}
                  >
                    take test
                  </button>
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default McqPage;

const cardData = [
  {
    id: 1,
    img: testimg,
    title: "test 1",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
  {
    id: 2,
    img: testimg,
    title: "test 2",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
  {
    id: 3,
    img: testimg,
    title: "test 3",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
  {
    id: 4,
    img: testimg,
    title: "test 4",
    totalMarks: 20,
    toatlQues: 20,
    totalTime: 20,
  },
];
