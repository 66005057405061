import React, { useState, useEffect, useRef, forwardRef, useContext } from "react";
import "./Navbar.css";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Redirect } from "react-router-dom";
import Search from "../Search/Search";
import scope from "../../Assets/Nav_Bar/Assets/1x/Asset 22.png";
import contact from "../../Assets/Nav_Bar/Assets/1x/Asset 23.png";
// import profile from "../../Assets/Nav_Bar/Assets/1x/Profile.svg";
import hamburgers from "../../Assets/Nav_Bar/Assets/1x/Asset 1.png";
import { AiOutlineUser } from "react-icons/ai";
import logo from "../../Assets/Nav_Bar/Assets/1x/Asset 4.png";
import Model from "../Model/Model";
import Popovr from "../Popover/Popovr";
import CallIcon from "@material-ui/icons/Call";
import DialogBox from "../DialogBox/DialogBox";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import Drawer from "../Drawer/Drawer";
import { useHistory } from "react-router-dom";
import footerLogo from "../../Assets/brainhap/footerLogo.png";
import drawerBG from "../../Assets/brainhap/sidebanner.png";
import { SwipeableDrawer } from "@material-ui/core";
import { api_call_token } from "../../Utils/Network";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

function Navbar({ executeScrolls = () => {}}) {
  const { setDisplayType, setFilter, user, setOpenModel, testDone } =
    useContext(UserCredsContext);
  const history = useHistory();
  const classes = useStyles();
  const [testType, setTestType] = useState(null);
  const myRef = useRef(null)
  // const [openModel, setOpenModel] = useState(false);
  const [isActive, setActive] = useState(false);
  const toggle = () => {
    setActive(!isActive);
  };
  const [colorChange, setColorchange] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const executeScroll = () => myRef.current.scrollIntoView({behavior:"smooth"})   
  const opneContact = () =>{
    history.push(`/#requestdemo`)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };

  const redirectExpert = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/ask-to-counselor");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };

  const navElement = () => {
    return (
      <div
        className={`mb_nv_flex flx_width nvb_side_bar newDrawer`}
        style={{ height: "100%" }}
      >
        <div className="flexTaktest">
          {/* onClick={() => redirects()}           */}
          {/* <Link style={{ color: "#032C6B" }} onClick={() => redirects()}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {testDone ? "View Test" : "Take Test"}
            </p>
          </Link>
          <Link onClick={() => redirectExpert()} style={{ color: "#032C6B" }}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              Book Career Counselling
            </p>
          </Link>
          <Link
            to="/blog"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Blog</p>
          </Link> */}

          {/* <Link style={{ color: "#032C6B" }} onClick={() => redirects()}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {testDone ? "View Test" : "Take Test"}
            </p>
          </Link> */}
          {/* <Link onClick={() => redirectExpert()} style={{ color: "#032C6B" }}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Product</p>
          </Link> */}
          <Link style={{ color: "#032C6B" }}>
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Product</p>
          </Link>
          <Link
            to="/stem"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>
              {/* Stem Accrediation */}
              STEM Courses
            </p>
          </Link>
          <Link
            to="/blog"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
          >
            <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Blog</p>
          </Link>
          <div>
            {/* {window.innerWidth > 992 ? (
            <></>
          ) : (
            <div>
              <Link
                to="/explore"
                style={{ color: "#032C6B" }}
                onClick={() => handleClose()}
              >
                <p style={{ fontWeight: "500", fontSize: "1.1rem" }}>Explore</p>
              </Link>
            </div>
          )} */}
            <div >
              {/* <Link to="#requestdemo"> */}
              <button className="btn_nv_tech btn_ng_gn" onClick={() => {opneContact(); executeScrolls()}} > Request demo </button>
              {/* </Link> */}
            </div>
          </div>
          {/* <div>
          <Link
            to="/blog"
            style={{ color: "#032C6B" }}
            onClick={() => handleClose()}
          >
            <p style={{ fontWeight: "500" }}>Login  </p>
          </Link>
        </div> */}
        </div>
        {/* <div>
          <button className="btn_nv_tech btn_ng_gn">
            <a
              href="tel:9653257335"
              style={{
                display: "flex",
                color: "white",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CallIcon style={{ marginRight: "10px" }} /> 9653257335
            </a>
          </button>
        </div> */}
      </div>
    );
  };

  // document.addEventListener("click", setActive(false))

  // let menuRef = useRef();

  // useEffect(() => {
  //   let handler = (event) => {
  //     if (!menuRef.current.contains(event.target)) {
  //       setActive(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);

  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // });
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <div className="topnumber-section">
        <marquee>
      
        <a
          href="tel:9653257335"
          className="changelinksforancor"
          style={{
            display: "flex",
            // color: "white",
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CallIcon style={{ marginRight: "10px" }} /> 9653257335  call to book a counselling session now!
        </a>
       </marquee>
      </div>
      <nav className={colorChange ? "navbar colorChange " : "navbar "}>
        <div className="nav_base">
          <div className="main_nav">
            <div className="hm-bg">
              <div className="hamburger">
                <img
                  src={hamburgers}
                  alt="hamburgers"
                  className="hamb"
                  onClick={handleClick}
                ></img>
                <SwipeableDrawer
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  {window.innerWidth < 992 ? navElement() : <></>}
                </SwipeableDrawer>
              </div>
              <div className="nav-logo">
                <a href="#">
                  <img src={footerLogo} alt="logo" className="logo"></img>
                </a>
              </div>
            </div>
          </div>

          <div className="mb_nv_flex rev_flx flx_width">
            {window.innerWidth > 992 ? navElement() : <></>}

            <div className="mobile_login main_nav">
              <Drawer />
            </div>
            <div>
              <div className="login">
                <DialogBox />
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* {openModel && <Model closeModel={setOpenModel} />} */}
    </>
  );
}

export default Navbar;
