import React from 'react'
import styles from './index.module.css'
import { Button } from '@material-ui/core';

function BuyKitCard(values) {

    console.log(values?.values?.id , "jsakdhajfdhajkdhadfjhadfj");
  return (
    <div className={styles.BuycardBox}>
        <div className={styles.imgContainer}>
            <img src={values?.values?.img} alt='' />
        </div>
        <div className={`${styles.textContainer}`}>
        <h5 className={styles.kitName}>{values?.values?.title}</h5>
        <p className={styles.kitdesc}>{values?.values?.desc}</p>
        <Button variant='contained' className={styles.buybtn}>Buy Now</Button>
        </div>
    </div>
  )
}

export default BuyKitCard