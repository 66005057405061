import React from 'react'
import styles from "./index.module.css"
import exploricon1 from "../../../Assets/NewHomepageImg/exploricon1.png";
import exploricon2 from "../../../Assets/NewHomepageImg/exploricon2.png"
import { Button } from '@mui/material';


const ExplorMore = (exploredataobj) => {

    console.log(exploredataobj, "exploredataobj");
  return (
    <section className={styles.Explorebtnsection}>
      <h4 className={styles.sectionHeading}>
      Our Association 
          </h4>
        <div className={styles.ExploreBackground}>
          {/* <img src={explorbanner} alt="explore" /> */}
        </div>
        <div className={styles.exploreimgTextbtn}>
          <div>
            <img src={exploredataobj?.exploredataobj?.imgOne} />
          </div>
          <h3 >{exploredataobj?.exploredataobj?.textOne}</h3>
          {
            exploredataobj?.exploredataobj?.textTwo != "" ? (
              <>
              <div>
              <img src={exploredataobj?.exploredataobj?.imgTwo} />
            </div>
            <h3>{exploredataobj?.exploredataobj?.textTwo}</h3>
            </>
            
            ) : ""
          }
         
          {/* <div className={styles.explorepc}>
            <Button variant="contained" className={styles.explorbtn}>
              Explore Now
            </Button>
          </div> */}
        </div>
        {/* <div className={styles.exploremobile}>
          <Button variant="contained" className={styles.explorbtn}>
            Explore Now
          </Button>
        </div> */}
        <div className={`${styles.postions} ${styles.iconboxone}`}>
          <img src={exploricon1} />
        </div>
        <div className={`${styles.postions} ${styles.iconboxtwo}`}>
          <img src={exploricon2} />
        </div>
      </section>
  )
}

export default ExplorMore