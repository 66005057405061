import React from "react";
import styles from "./index.module.css";
import cmt from "../../../Assets/NewHomepageImg/newhowdoesitwork/MicrosoftTeams-image (1).png";

function HowdoesitworkComponent(values) {
  const Data = values?.values;
  console.log(Data, "howdoesitworkNewComponent");
  return (
    <>
      <div style={{background:Data?.backgroundColor}} className={`${styles.mainCardContainer}`}>
        <div className={`${styles.LeftImgContainer}`}>
          <img src={Data?.img} alt="" />
        </div>
        <div className={`${styles.rightTextContainer}`}>
          <h4
            style={{ color: Data?.fontCol }}
            className={`${styles.CardTitles}`}
          >
            {Data?.title}
          </h4>
          {/* <p className={`${styles.cardpara}`}>{Data?.para}</p> */}
          <p className={`${styles.cardpara}`} dangerouslySetInnerHTML={{ __html: Data?.para }}></p>

          <ul className={`${styles.pointsListing}`}>
            {Data?.pointes?.map((v) => {
              return (
                <>
                  {/* <li>{v?.text}</li> */}
                  <li dangerouslySetInnerHTML={{ __html: v?.text }}></li>
                </>
              );
            })}
          </ul>
          <p style={{ color: Data?.fontCol }} className={`${styles.noteText}`}>
            {Data?.para2}
          </p>
        </div>
      </div>
    </>
  );
}

export default HowdoesitworkComponent;
