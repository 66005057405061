import React, { useContext, useRef, useState } from "react";
import styles from "./index.module.css";
import { Link, Redirect } from "react-router-dom";
import bannerimg from "../../Assets/bannernew1.png";
import StemAccredition from "../../Assets/Stem-Accredition .png";
import explorbanner from "../../Assets/explorBackgound.png";
import iitbombey from "../../Assets/iitbombeylogo.png";
import newIitbombey from "../../Assets/NewHomepageImg/newiitbombeylogo.png";
import { Button, TextField } from "@material-ui/core";
import supp1 from "../../Assets/HomeRewamp/sir.jpeg";
import supp2 from "../../Assets/HomeRewamp/supportBy2.png";
import ArrowForward from "@material-ui/icons/ArrowForward";
import vector from "../../Assets/howtodovectorbg.png";
import careermappingtest from "../../Assets/NewHomepageImg/newhowdoesitwork/MicrosoftTeams-image (1).png";
import careercounselling from "../../Assets/NewHomepageImg/newhowdoesitwork/career counselling.png";
import brainMappingtest from "../../Assets/NewHomepageImg/newhowdoesitwork/brain mapping test.png";
import neuromentoring from "../../Assets/NewHomepageImg/newhowdoesitwork/neuromentoring.png";
import evoSkilllab from "../../Assets/NewHomepageImg/newhowdoesitwork/evo skill lab.png";
import stemcourse from "../../Assets/STEM course.png";
import careermapping from "../../Assets/NewHomepageImg/ALPHAGUIDE.svg";
import brainmapping from "../../Assets/NewHomepageImg/MINDGRAPHER.svg";
import neuromen from "../../Assets/NewHomepageImg/NEUROMENTOR.svg";
import stemLab from "../../Assets/NewHomepageImg/EVOSKILL.svg";
import Carousel from "react-multi-carousel";
// import { responsiveCourses } from "../../Utils/Utils";
import imgone from "../../Assets/NewHomepageImg/G-img-1.png";
import imgtwo from "../../Assets/NewHomepageImg/G-img-2.png";
import imgthree from "../../Assets/NewHomepageImg/G-img-3.png";
import imgfour from "../../Assets/NewHomepageImg/G-img-4.png";
import imgfive from "../../Assets/NewHomepageImg/G-img-5.png";
import imgsix from "../../Assets/NewHomepageImg/G-img-6.png";
import imgseven from "../../Assets/NewHomepageImg/G-img-7.png";
import imgeight from "../../Assets/NewHomepageImg/G-img-8.png";
import imgnine from "../../Assets/NewHomepageImg/G-img-9.png";
import NewCarousel from "../../Components/Carousels/newCarousel";
import { InputAdornment } from "@mui/material";
import mobilevector from "../../Assets/NewHomepageImg/howtodovectorbg-mobile.png";
import imgmone from "../../Assets/NewHomepageImg/MG-img-1.png";
import imgmtwo from "../../Assets/NewHomepageImg/MG-img-2.png";
import imgmthree from "../../Assets/NewHomepageImg/MG-img-3.png";
import imgmfour from "../../Assets/NewHomepageImg/MG-img-4.png";
import imgmfive from "../../Assets/NewHomepageImg/MG-img-5.png";
import imgmsix from "../../Assets/NewHomepageImg/MG-img-6.png";
import imgmseven from "../../Assets/NewHomepageImg/MG-img-7.png";
import imgmeight from "../../Assets/NewHomepageImg/MG-img-8.png";
import imgmnine from "../../Assets/NewHomepageImg/MG-img-9.png";
import bannermobile from "../../Assets/NewHomepageImg/Banner 1(boy).png";
import exploricon1 from "../../Assets/NewHomepageImg/exploricon1.png";
import exploricon2 from "../../Assets/NewHomepageImg/exploricon2.png";
import ricon1 from "../../Assets/NewHomepageImg/rIcon-1.png";
import ricon2 from "../../Assets/NewHomepageImg/ricon-2.png";
import ricon3 from "../../Assets/NewHomepageImg/ricon-3.png";
import workIcon1 from "../../Assets/NewHomepageImg/workicon-1.png";
import workIcon2 from "../../Assets/NewHomepageImg/workicon-2.png";
import workIcon3 from "../../Assets/NewHomepageImg/workicon-3.png";
import workIcon4 from "../../Assets/NewHomepageImg/workicon-4.png";
import workIcon5 from "../../Assets/NewHomepageImg/workicon-5.png";
import workIcon6 from "../../Assets/NewHomepageImg/workicon-6.png";
import cf1 from "../../Assets/NewHomepageImg/cf-1.png";
import cf2 from "../../Assets/NewHomepageImg/cf-2.png";
import gicon from "../../Assets/NewHomepageImg/galleryicon1.png";
import psicon1 from "../../Assets/NewHomepageImg/ps-1.png";
import psicon2 from "../../Assets/NewHomepageImg/ps-2.png";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { useHistory } from "react-router-dom";
import { api_call } from "../../Utils/Network";
import { Snackbar, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import ExplorMore from "../../Components/Newdesigncomponets/ExploreMore/ExplorMore";
import CheckIcon from "@material-ui/icons/Check";
import Grow from "@material-ui/core/Grow";
import BannerSlider from "../../Components/Newdesigncomponets/NewBanneSlider/BannerSlider";
import bannerone from "../../Assets/NewHomepageImg/bannerboy-1.png";
import bannertwo from "../../Assets/NewHomepageImg/bannertwo.png";
import bannerthree from "../../Assets/NewHomepageImg/banerthree.png";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import HowdoesitworkComponent from "../../Components/Newdesigncomponets/HowdoesitworkComponent/HowdoesitworkComponent";
import NewReviewsCarousel from "../../Components/Newdesigncomponets/NewReviewsCarousel/NewReviewsCarousel";
// import img from "../../Assets/NewHomepageImg/G-img-1.png"
import teacherpic from "../../Assets/NewHomepageImg/newhowdoesitwork/reviwes-pic.png";
import girl_Illustrator from "../../Assets/brainhap/girl_Illustrator.svg";
import teacherpicmob from "../../Assets/NewHomepageImg/newhowdoesitwork/formobilreview.png";
import g1 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-1.png";
import g2 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-2.png";
import g3 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-3.png";
import g4 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-4.png";
import g5 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-5.png";
import g6 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-6.png";
import g7 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-7.png";
import g8 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-8.png";
import g9 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-9.png";
import g10 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-10.png";
import g11 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-11.png";
import g12 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-12.png";
import g13 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-13.png";
import g14 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-14.png";
import g15 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-15.png";
import g16 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-16.png";
import g17 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-17.png";
import g18 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-18.png";
import g19 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-19.png";
import g20 from "../../Assets/NewHomepageImg/newhowdoesitwork/g-20.png";
import banner3 from "../../Assets/NewHomepageImg/newhowdoesitwork/banner-iitblogo.png";
import banner4 from "../../Assets/NewHomepageImg/newhowdoesitwork/banner-stemlogo.png";

// function GrowTransition(props) {
//   return <Grow {...props} />;
// }
const styleTwo = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  // alignItems:"center"
};
function HomePageNew({ myRef, executeScroll = () => {} }) {
  const grades = [5, 6, 7, 8, 9, 10, 11, 12];
  const [activeIndex, setActiveIndex] = useState(null);
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState({ name: "" });
  const { setDisplayType, setOpenModel, user } = useContext(UserCredsContext);
  const [testType, setTestType] = useState(null);
  const playerRef = useRef(null);
  const [selectedGrades, setSelectedGrades] = useState([]);
  const history = useHistory();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [finelOpne, setFinelOpne] = useState(false);
  // const myRef = useRef(null)
  const [userData, setUserData] = useState({
    contact_person_name: "",
    // c_lname: "",
    c_phone: "",
    c_email: "",
    c_message: "",
    c_school: "",
  });
  var temp = {};

  // const executeScroll = () => myRef.current.scrollIntoView({behavior:"smooth"})

  const validate = () => {
    let num = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    var validEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let returnValue = true;
    temp.contact_person_name =
      userData.contact_person_name === "" ? "Please enter Your Name" : "";
    temp.c_phone = num.test(userData.c_phone)
      ? ""
      : "Please Enter valid number";
    // temp.c_email = userData.c_email.match(validEmail)
    //   ? ""
    //   : "Please Enter valid email";
    // temp.c_message = userData.c_message === "" ? "Please Enter valid Message" : "";
    temp.c_school = userData.c_school === "" ? "Please Enter School Name" : "";

    setErrors({
      ...temp,
    });
    var found = Object.keys(temp).filter(function (key) {
      return temp[key].includes("Please");
    });
    if (found.length) {
      console.log(temp);
      returnValue = false;
    }
    return returnValue;
  };

  const responsiveCoursesgallry = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const handleshowactive = (i, v) => {
    console.log(v, "needtoconform");
    setActiveIndex(i);
    const selectedIndex = selectedGrades.indexOf(v);
    // const massage = {c_message:}
    // setUserData( massage)
    //   if (v != ""){
    //   setUserData({ ...userData, c_message: `Interested in this Grades ${v}th` });
    // }
    // If the grade is not selected, add it to the array.

    // if (v != "") {
    //   if (selectedIndex === -1) {
    //     selectedGrades.push(v);
    //     setSelectedGrades([...selectedGrades]);
    //     setUserData({
    //       ...userData,
    //       c_message: `Interested in this Grades ${selectedGrades.join(
    //         "th, "
    //       )}th`,
    //     });
    //   } else {
    //     // If the grade is already selected, remove it from the array.
    //     const updatedGrades = [...selectedGrades];
    //     updatedGrades.splice(selectedIndex, 1);
    //     setSelectedGrades(updatedGrades);
    //     setUserData({
    //       ...userData,
    //       c_message: `Interested in this Grades ${updatedGrades.join(
    //         "th, "
    //       )}th`,
    //     });
    //   }
    // }
    // const mes = `Interested in this Grades ${selectedGrades.join('th, ')}th`;
  };

  console.log("USERSAAAAA", userData);

  const redirects = () => {
    if (user.user_type === 1) {
      // history.push('/taketest')
      history.push("/testseries");
    } else {
      setDisplayType(0);
      setOpenModel(true);
      setTestType(1);
    }
  };
  console.log(userData, "showuserdata");
  const submitForm = () => {
    if (!validate()) return null;
    var payload = {
      first_name: userData.contact_person_name,
      // last_name: userData.c_lname,
      phone: userData.c_phone,
      email: userData.c_email,
      message: userData.c_message,
      school: userData.c_school,
    };

    console.log(payload, "contactfromdata");

    // if(payload){
    //   setMsg("Thank You! Your submission has been sent.");
    // }
    // setMsg("Thank You! Your submission has been sent.");
    api_call
      .post("contact/general/", payload)
      .then((x) => {
        setMsg("Thank You! Your submission has been sent.");
        setUserData({
          contact_person_name: "",
          c_phone: "",
          c_email: "",
          c_message: "",
          c_school: "",
        });
        document.getElementById("outlined-name").value = "";
        document.getElementById("outlined-email").value = "";
        document.getElementById("outlined-phone").value = "";
        document.getElementById("outlined-school").value = "";
        document.getElementById("outlined-multiline-static").value = "";
        // setSelectedGrades([]);
      })
      .catch((error) => console.log(error));
  };

  const handle = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const viewVideoOpne = (video) => {
    setSelectedVideo(video);
    setFinelOpne(true);
  };
  const viewVideoClose = () => setFinelOpne(false);

  return (
    <>
      {/* <section className={styles.HomeBanner}>
        <div className={`${styles.backgroundBanner} ${styles.hidemobile}`}>
          <img src={bannerimg} alt="banner" />
        </div>
        <div className={`${styles.backgroundBanner} ${styles.showmobile}`}>
          <img src={bannermobile} alt="banner" />
        </div>
        <div className={styles.bannerTexts}>
          <h1 className={styles.bannertagLine}>
            Introducing Revolutionary & India’s Own Brain Mapping Technology
          </h1>
          <p className={styles.bannerpara}>
            Empowering young individuals to achieve excellence with{" "}
            <span>Neurotechnology</span>.
          </p>
          <Button
            variant="contained"
            className={styles.btnGradient}
            onClick={() => {
              redirects();
            }}
          >
            Take the Test
            <ArrowForward />
          </Button>
        </div>
      </section> */}
      <BannerSlider
        values={bannerdata}
        setFinelOpne={setFinelOpne}
        executeScroll={executeScroll}
      />
      {/* <section className={styles.Explorebtnsection}>
        <div className={styles.ExploreBackground}>
          
        </div>
        <div className={styles.exploreimgTextbtn}>
          <div>
            <img src={StemAccredition} />
          </div>
          <h3>Stem Accredition |</h3>
          <div>
            <img src={iitbombey} />
          </div>
          <h3>IITBOMBAY</h3>
          <div className={styles.explorepc}>
            <Button variant="contained" className={styles.explorbtn}>
              Explore Now
            </Button>
          </div>
        </div>
        <div className={styles.exploremobile}>
          <Button variant="contained" className={styles.explorbtn}>
            Explore Now
          </Button>
        </div>
        <div className={`${styles.postions} ${styles.iconboxone}`}>
          <img src={exploricon1} />
        </div>
        <div className={`${styles.postions} ${styles.iconboxtwo}`}>
          <img src={exploricon2} />
        </div>
      </section> */}
      {/* <ExplorMore exploredataobj={exploreobje} /> */}
      <section className={styles.accociationSection}>
        <h4 className={styles.sectionHeading}>Our Association</h4>
        <div className={styles.maininnerlogoText}>
          <div className={styles.logotext}>
            <div className={styles.innerlogocontainer}>
              <img src={newIitbombey} alt="iit-bobbey" />
            </div>
            <h3 className={styles.onlytext}>IIT BOMBAY</h3>
          </div>
          <div className={styles.logotext}>
            <div
              className={`${styles.innerlogocontainer} ${styles.widthforlogo}`}
            >
              <img src={StemAccredition} alt="stem" />
            </div>
            <h3 className={styles.onlytext}>STEM.ORG </h3>
          </div>
        </div>
        <div className={`${styles.postions} ${styles.iconboxone}`}>
          <img src={exploricon1} />
        </div>
        <div className={`${styles.postions} ${styles.iconboxtwo}`}>
          <img src={exploricon2} />
        </div>
      </section>
      {/* <section className={styles.LJOS}>
        <div className={styles.textContainer}>
          <h4 className={styles.sectionHeading}>
            Research-Based Technology to Reform Learning Journey of Students
          </h4>
          <h4 className={styles.sectionHeading}>
            Our Scientific Advisor & Mentor
          </h4>
          <p>
            Developed over 10 years, BrainHap’s neurotechnology system is
            created in Association with IIT Bombay under the supervision of Dr.
            Azizuddin Khan. The technology is used to combine “reformative
            counselling” with “next-gen skill development”. The program is
            nurtured by our team of experts who have over 25+ years of
            experience in Cognitive Psychology & Psychophysiology.
          </p>
        </div>

        <div className={styles.imagestoshow}>
          <div className={styles.LJOSimgContainer}>
            <img src={supp1} alt="" />
          </div>
          <div className={styles.LJOSimgContainer}>
            <img src={supp2} alt="" />
          </div>
        </div>
        <div className={styles.LJOSbtnContainer}>
          <Button
            variant="contained"
            className={styles.btnGradient}
            onClick={() => {
              redirects();
            }}
          >
            Take the Test
            <ArrowForward />
          </Button>
        </div>
        
        <div className={`${styles.postions} ${styles.riconone}`}>
          <img src={ricon1} />
        </div>
        <div className={`${styles.postions} ${styles.ricontwo}`}>
          <img src={ricon2} />
        </div>
        <div className={`${styles.postions} ${styles.riconthree}`}>
          <img src={ricon3} />
        </div>
      </section> */}
      <section className={styles.LJOS}>
        <div className={styles.textContainer}>
          {/* <h4 className={styles.sectionHeading}>
            Research-Based Technology to Reform Learning Journey of Students
          </h4> */}
          <h4 className={styles.sectionHeading}>
            Our Scientific Advisor & Mentor
          </h4>
          {/* <p>
            Developed over 10 years, BrainHap’s neurotechnology system is
            created in Association with IIT Bombay under the supervision of Dr.
            Azizuddin Khan. The technology is used to combine “reformative
            counselling” with “next-gen skill development”. The program is
            nurtured by our team of experts who have over 25+ years of
            experience in Cognitive Psychology & Psychophysiology.
          </p> */}
        </div>

        {/* <div className={styles.imagestoshow}> */}
        <div className={styles.mainforflex}>
          <div className={styles.LJOSimgContainer}>
            <img src={supp1} alt="" />
          </div>
          <div className={styles.textrightcontainer}>
            <h2 className={styles.hisName}>DR. AZIZUDDIN KHAN </h2>
            <div className={styles.iitslogoandname}>
              <div className={styles.iitslogoConatiner}>
                <img src={newIitbombey} alt="iit" />
              </div>
              <h4 className={styles.iitsName}>IIT BOMBAY</h4>
            </div>
            <h4 className={styles.iitsName}>
              Cognitive Psychology and Psychophysiology{" "}
            </h4>
            <h4 className={styles.iitsName}>24+ years of experience</h4>
            <p className={styles.hisQuote}>
              “BrainHap, uses one of the best approaches to reform learning
              patterns in young students.”
            </p>
          </div>
        </div>
        {/* <div className={styles.LJOSimgContainer}>
            <img src={supp2} alt="" />
          </div> */}
        {/* </div> */}
        <div className={styles.LJOSbtnContainer}>
          <Button
            variant="contained"
            className={styles.btnGradient}
            // onClick={() => {
            //   redirects();
            // }}

            onClick={executeScroll}
          >
            Request Demo
            <ArrowForward />
          </Button>
        </div>

        <div className={`${styles.postions} ${styles.riconone}`}>
          <img src={ricon1} />
        </div>
        <div className={`${styles.postions} ${styles.ricontwo}`}>
          <img src={ricon2} />
        </div>
        <div className={`${styles.postions} ${styles.riconthree}`}>
          <img src={ricon3} />
        </div>
      </section>
      <section className={styles.servicesSection}>
        <h4 className={styles.sectionHeading}>Our Products</h4>
        {/* <p className={styles.serviceInfopara}>
          Harness the power of your brain with the help of cutting-edge
          neurotechnology and AI/ML-based suggestions.
        </p> */}
        <div className={styles.servicessContainer}>
          {ourServices?.map((v, i) => {
            return (
              <div>
                <div>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <h4 className={`${styles.product_title}`}>{v?.title}</h4>
                {/* <Button variant="contained" className={styles.btnGradient}>
                  Read More
                </Button> */}
                <Link
                  to={v?.url}
                  style={{ color: "#032C6B" }}
                  // onClick={() => handleClose()}
                >
                  <Button variant="contained" className={styles.btnGradient}>
                  Read More
                </Button>
                </Link>
              </div>
            );
          })}
        </div>
      </section>
      <section className={styles.howitsWork}>
        <h4 className={styles.sectionHeading}>How does it work?</h4>
        <p className={styles.howitspara}>
          We set up a center for career counselling and skill training called
          EVOSKILL LAB for class 6 to 12 which are equipped with brainmapping
          technology and skill training courses.{" "}
        </p>
        <p className={styles.howitspara} style={{ margin: "20px auto" }}>
          Here we will conduct the following activities.
        </p>
        {/* <div className={styles.hidemobile}>
          <div
            className={styles.howitsWork_Backgoundline}
            //   style={{ textAlign: "center" }}
          >
            <img style={{ margin: "0 auto" }} src={vector} alt="vector" />
          </div>
        </div> */}
        {/* <div className={styles.showmobile}>
          <div
            className={styles.howitsWork_Backgoundline}
            //   style={{ textAlign: "center" }}
          >
            <img style={{ margin: "0 auto" }} src={mobilevector} alt="vector" />
          </div>
        </div> */}
        {/* <ul className={styles.hdiw_maincontainer}>
          {howdoesitwork2?.map((v, i) => {
            return (
              <li key={i}>
                <div className={styles.hdiw_imgContainer}>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <div className={styles.InfoBox}>
                  <h5>{v?.title}</h5>
                  <p>{v?.para}</p>
                  <ul className={styles.mainpointers}>
                    {v?.list?.map((q) => {
                      return (
                        <li>
                          <p className={styles.mainpointerstext}>{q?.text}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul> */}
        {howdoesitwork?.map((v) => {
          return (
            <>
              <HowdoesitworkComponent values={v} />
            </>
          );
        })}
        {/* <HowdoesitworkComponent  values={howdoesitwork}/> */}
        <div className={`${styles.postions} ${styles.howIcon1}`}>
          <img src={workIcon1} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon2}`}>
          <img src={workIcon2} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon3}`}>
          <img src={workIcon3} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon4}`}>
          <img src={workIcon4} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon5}`}>
          <img src={workIcon5} />
        </div>
        <div className={`${styles.postions} ${styles.howIcon6}`}>
          <img src={workIcon6} />
        </div>
      </section>
      {/* <section className={styles.servicesSection}>
        <h4 className={styles.sectionHeading}>Our Services</h4>
        <p className={styles.serviceInfopara}>
          Harness the power of your brain with the help of cutting-edge
          neurotechnology and AI/ML-based suggestions.
        </p>
        <div className={styles.servicessContainer}>
          {ourServices?.map((v, i) => {
            return (
              <div>
                <div>
                  <img src={v?.img} alt={v?.title} />
                </div>
                <h4>{v?.title}</h4>
                <Button variant="contained" className={styles.btnGradient}>
                  Learn more
                </Button>
              </div>
            );
          })}
        </div>
      </section> */}
      <section className={styles.gallerySection}>
        <h4 className={styles.sectionHeading}>Gallery</h4>
        <Carousel
          responsive={responsiveCoursesgallry}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          arrows={false}
          // className={styles.slider}
        >
          {Gallerydata?.map((v, i) => {
            return (
              <>
                <div
                  key={i}
                  className={`${styles.galleryimgContainer} ${styles.hidemobile}`}
                >
                  <img src={v?.img} alt={v.id} />
                  {/* <h1>{v?.id}</h1> */}
                </div>
                <div
                  key={i}
                  className={`${styles.galleryimgContainer} ${styles.showmobile}`}
                >
                  <img src={v?.mobileimg} alt={v.id} />
                  {/* <h1>{v?.id}</h1> */}
                </div>
              </>
            );
          })}
        </Carousel>
        <div className={`${styles.postions} ${styles.galleryIcon2}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.galleryIcon1}`}>
          <img src={gicon} />
        </div>
      </section>
      <section className={styles.patnerSchoolSection}>
        <div className={` ${styles.mobile_container} `}>
          <div className={`${styles.textCenter}`}>
            <h3 className={`${styles.bluiesh} ${styles.featuresdiv}`}>
              Reviews from Our Partner Schools
            </h3>
          </div>
          {/* <NewCarousel sqd={[testimonialData]}></NewCarousel> */}
          <NewReviewsCarousel sqd={[testimonialData]} />
          <div className={`${styles.postions} ${styles.psicons1}`}>
            <img src={psicon1} />
          </div>
          <div className={`${styles.postions} ${styles.psicons2}`}>
            <img src={psicon2} />
          </div>
        </div>
      </section>
      <section
        className={styles.contactformSection}
        id="requestdemo"
        ref={myRef}
      >
        <div className={`${styles.innercontactContainer}`}>
          <div className={styles.borderContainer}>
            <div className={styles.bgacolor}>
              <div className={styles.fromContainer}>
                <h4 className={styles.sectionHeading}>Request Demo</h4>
                <p className={styles.serviceInfopara}>
                  Be the first one to get to know about BrainHap
                </p>

                <div className={styles.textfieldContainer}>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-name"
                      variant="outlined"
                      label="Enter name"
                      placeholder="Enter name"
                      name="contact_person_name"
                      type="text"
                      className={styles.fullwidth}
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.contact_person_name}
                    </p>
                  </div>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-email"
                      variant="outlined"
                      label="Enter email"
                      placeholder="Enter email"
                      type="text"
                      className={styles.fullwidth}
                      name="c_email"
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.c_email}
                    </p>
                  </div>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-phone"
                      variant="outlined"
                      label="Mobile Number"
                      // placeholder="Mobile Number"
                      type="text"
                      name="c_phone"
                      className={styles.fullwidth}
                      style={{ textAlign: "left" }}
                      onChange={handle}
                      // maxRows="10"
                      InputProps={{
                        startAdornment: (
                          // <input type="text" value="+91 |" readOnly style={{border:"none", background:'transparent'}} />
                          <InputAdornment position="start">
                            +91 |
                          </InputAdornment>
                        ),
                      }}
                      // required
                    />
                  </div>
                  <p style={{ margin: "0", color: "red" }}>{errors.c_phone}</p>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-school"
                      variant="outlined"
                      label="Enter school"
                      placeholder="Enter school"
                      type="text"
                      className={styles.fullwidth}
                      name="c_school"
                      onChange={handle}
                      // required
                    />
                    <p style={{ margin: "0", color: "red" }}>
                      {errors.c_school}
                    </p>
                  </div>
                  <div className={styles.innerbox}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Your message"
                      name="c_message"
                      multiline
                      className={styles.fullwidth}
                      rows={7}
                      variant="outlined"
                      style={{ margin: "20px 0" }}
                      onChange={handle}
                    />
                  </div>
                  <p style={{ margin: "0", color: "red" }}>
                    {errors.c_message}
                  </p>
                </div>
                {/* <p className={styles.serviceInfopara}>Student grade at school</p>
              <div className={styles.gradeContainer}>
                {grades?.map((v, i) => {
                  const isActive = selectedGrades.includes(v);
                  return (
                    <h3
                      className={`${styles.minBox} ${
                        isActive ? styles.active : ""
                      }`}
                      key={i}
                      onClick={() => handleshowactive(i, v)}
                    >
                      {v}
                      <sup>th</sup>
                    </h3>
                  );
                })}
              </div>
              <p style={{ margin: "0", color: "red" }}>{errors.c_message}</p>
              <div className={styles.textbtn}>School Enquiry</div> */}
                <Button
                  variant="contained"
                  className={styles.fromsubmitbtn}
                  onClick={submitForm}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          <div className={`${styles.contactformImgContainer}`}>
            <img src={girl_Illustrator} />
          </div>
        </div>
        <div className={`${styles.postions} ${styles.cfone}`}>
          <img src={cf1} />
        </div>
        <div className={`${styles.postions} ${styles.cftwo}`}>
          <img src={cf2} />
        </div>
        <div className={`${styles.postions} ${styles.cfthree}`}>
          <img src={workIcon3} />
        </div>
      </section>
      {msg && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setMsg("")}
          autoHideDuration={3000}
          message={msg}
          TransitionComponent={Grow}
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CheckIcon className={styles.formsubmited} />
          {/* <Alert severity="success"></Alert> */}
        </Snackbar>
      )}

      <Modal
        open={finelOpne}
        onClose={viewVideoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTwo} className={styles.boxForMobile}>
          <div
            className={styles.closebtn}
            style={{ textAlign: "right", cursor: "pointer" }}
          >
            <CloseIcon onClick={viewVideoClose} />
          </div>
          <div className={styles.videoModel}>
            <ReactPlayer
              controls={true}
              ref={(player) => {
                playerRef.current = player;
              }}
              url={selectedVideo?.file}
              // url="https://demo.unified-streaming.com/k8s/features/stable/video/tears-of-steel/tears-of-steel.ism/.m3u8"
              // width="100%"
              // height="500px"
              className={styles.innerVideoDisplay}
              style={{
                margin: "0 auto",
                backgroundColor: "#000",
              }}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default HomePageNew;

const howdoesitwork = [
  {
    id: 1,
    img: careermappingtest,
    title: "Alphaguide Test",
    fontCol: "#16838C",
    backgroundColor:
      "linear-gradient(180deg, rgba(212, 255, 242, 0.8) 0%, rgba(172, 214, 214, 0.8) 100%)",
    para: "India’s first <span style=font-weight:700> Career Mapping Test </span>, designed and developed with IIT Bombay.",
    para2: "Basic report with test scores.",
    pointes: [
      {
        text: "Age-wise Three Unique Tests i.e Aptitude, Interest and Academics.",
      },
      {
        text: "Uses 27 parameters in all.",
      },
      {
        text: "Identify and measure strong and weak parameters.",
      },
      // {
      //   text: "Basic report with test scores.",
      // },
      // {
      //   text: "Vocational skillset",
      // },
    ],
  },
  {
    id: 2,
    img: careercounselling,
    title: "Alphaguide Counselling",
    fontCol: "#508946",
    backgroundColor:
      "linear-gradient(180deg, rgba(213, 230, 212, 0.8) 0%, rgba(184, 216, 181, 0.8) 100%)",
    para: "A detailed <span style=font-weight:700> Career Counselling Session </span> with counsellor.",
    para2: "Advanced report with career recommendations.",
    pointes: [
      {
        text: "Thorough analysis of the career mapping results.",
      },
      {
        text: '"Dream Career" analysis.',
      },
      {
        text: "Identify current ability.",
      },
      {
        text: "Judgement and Recommendation.",
      },
      // {
      //   text: "Vocational skill development",
      // },
    ],
  },
  {
    id: 3,
    img: brainMappingtest,
    title: "Mindgrapher",
    fontCol: "#B46E6E",
    backgroundColor:
      "linear-gradient(180deg, rgba(250, 242, 242, 0.8) 0%, rgba(243, 225, 225, 0.8) 100%)",
    para: "<span style=font-weight:700>BrainMapping Test </span> to monitor the neuro-cognitive learning ability.",
    para2: "Continuous usage to unlock new career options.",
    pointes: [
      {
        text: "Uses six unique parameters like attention, inattention, focus and so on.",
      },
      // {
      //   text: "Six unique parameters",
      // },
      {
        text: "Identify interest and map with aptitude parameters to find best fit career options.",
      },
      {
        text: "Detailed scores for tracking changes in learning patterns.",
      },
      // {
      //   text: "Detailed scores",
      // },
      // {
      //   text: "Shift learning patterns",
      // },
    ],
  },
  {
    id: 4,
    img: neuromentoring,
    title: "Neuro-Mentoring",
    fontCol: "#6286AB",
    backgroundColor:
      "linear-gradient(180deg, rgba(236, 242, 249, 0.8) 0%, rgba(200, 217, 235, 0.8) 100%)",
    para: "A trained counsellor will provide <span style=font-weight:700> Neuro-Based Reformative Mentoring </span> .",
    para2: "Guaranteed improvement in learning abilities.",
    pointes: [
      {
        text: "Provides personalised, scientifically-proven techniques to improve weak areas.",
      },
      {
        text: "Cognitive training",
      },
      {
        text: "Techniques like cognitive training, mindfulness and so on.",
      },
      {
        text: "Data-based career mentoring reports to monitor improvement.",
      },
      // {
      //   text: "Spatial memory improvement",
      // },
    ],
  },
  {
    id: 5,
    img: evoSkilllab,
    fontCol: "#4D8092",
    backgroundColor:
      "linear-gradient(180deg, rgba(232, 236, 241, 0.8) 0%, rgba(181, 207, 216, 0.8) 100%)",
    title: "Stem Courses",
    para: "Age-appropriate next-gen skill courses",
    para2: "Increasing student probability of achieving dream career.",
    pointes: [
      {
        text: "Designed and developed by <span style=font-weight:700> certified STEM experts</span> .",
      },
      {
        text: "Courses like Drone, Augmented Reality (AR), Virtual Reality (VR), Coding, Artificial Intelligence (AI).",
      },
      {
        text: "Run parallel with academic subjects.",
      },
      {
        text: "Increasing student probability to achieve dream career.",
      },
      {
        text: "Certified by <span style=font-weight:700> STEM.ORG</span> ",
      },
    ],
  },
];

const howdoesitwork2 = [
  {
    id: 1,
    img: careermappingtest,
    title: "ALPHAGUIDE TEST ",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
    list: [
      {
        text: "India’s first Career Mapping Test, designed and developed with IIT Bombay.",
      },
      {
        text: "Age-wise Three Unique Tests i.e Aptitude, Interest and Academics",
      },
      {
        text: "Uses 25 parameters in all.",
      },
      {
        text: "Identify and measure strong and weak parameters.",
      },
      {
        text: "Basic report with test scores.",
      },
    ],
  },
  {
    id: 2,
    img: careercounselling,
    title: "ALPHAGUIDE COUNSELLING  ",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
    list: [
      {
        text: "A detailed career counselling session with counsellor.",
      },
      {
        text: "Thorough analysis of the career mapping results.",
      },
      {
        text: "Dream Career analysis.",
      },
      {
        text: "Identify current ability.",
      },
      {
        text: "Advanced report with suggested career options.",
      },
      {
        text: "Judgement and Recommendation.",
      },
    ],
  },
  {
    id: 3,
    img: brainMappingtest,
    title: "MINDGRAPHER",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
    list: [
      {
        text: "BrainMapping test to monitor the neuro-cognitive learning ability.",
      },
      {
        text: "Uses six unique parameters like attention, inattention, focus and so on.",
      },
      {
        text: "Identify interest and map with aptitude parameters to find best fit career options.",
      },
      {
        text: "Detailed scores for tracking changes in learning patterns.",
      },
      {
        text: "Continuous usage to unlock new career options.",
      },
    ],
  },
  {
    id: 4,
    img: neuromentoring,
    title: "NEUROMENTORING",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
    list: [
      {
        text: "A trained counsellor will provide neuro-based reformative counselling.",
      },
      {
        text: "Provides personalised, scientifically-proven techniques to improve weak areas.",
      },
      {
        text: "Techniques like cognitive training, mindfulness and so on.",
      },
      {
        text: "Data-based career mentoring reports to monitor improvement.",
      },
    ],
  },
  {
    id: 5,
    img: stemcourse,
    title: "STEM COURSES",
    para: "Tracking attention, inattention, and focus parameters in real-time and providing neuro-feedback insights.",
    list: [
      {
        text: "Age-appropriate next-gen skill courses.",
      },
      {
        text: "Designed and developed by certified STEM experts.",
      },
      {
        text: "Courses like Drone, Augmented Reality (AR), Virtual Reality (VR), Coding, Artificial Intelligence (AI).",
      },
      {
        text: "Run parallel with academic subjects.",
      },
      {
        text: "Increasing student probability to achieve dream career.",
      },
      {
        text: "Certified by STEM.ORG",
      },
    ],
  },
];

const ourServices = [
  {
    id: 1,
    img: careermapping,
    title: "ALPHAGUIDE",
  },
  {
    id: 2,
    img: brainmapping,
    title: "MINDGRAPHER",
  },
  {
    id: 3,
    img: neuromen,
    title: "NEUROMENTOR",
  },
  {
    id: 4,
    img: stemLab,
    title: "EVOSKILL/STEM LAB",
    url: "/evoskills",
  },
];

const Gallerydata = [
  // {
  //   id: 1,
  //   img: imgone,
  //   mobileimg: imgmone,
  // },
  // {
  //   id: 2,
  //   img: imgtwo,
  //   mobileimg: imgmtwo,
  // },
  {
    id: 3,
    img: imgthree,
    mobileimg: imgmthree,
  },
  {
    id: 4,
    img: imgfour,
    mobileimg: imgmfour,
  },
  {
    id: 5,
    img: imgfive,
    mobileimg: imgmfive,
  },
  {
    id: 6,
    img: imgsix,
    mobileimg: imgmsix,
  },
  {
    id: 7,
    img: imgseven,
    mobileimg: imgmseven,
  },
  {
    id: 8,
    img: imgeight,
    mobileimg: imgmeight,
  },
  {
    id: 9,
    img: imgnine,
    mobileimg: imgmnine,
  },
  {
    id: 10,
    img: g1,
    mobileimg: g1,
  },
  {
    id: 11,
    img: g2,
    mobileimg: g2,
  },
  {
    id: 12,
    img: g3,
    mobileimg: g3,
  },
  {
    id: 13,
    img: g4,
    mobileimg: g4,
  },
  {
    id: 14,
    img: g5,
    mobileimg: g5,
  },
  {
    id: 15,
    img: g6,
    mobileimg: g6,
  },
  {
    id: 16,
    img: g7,
    mobileimg: g7,
  },
  {
    id: 17,
    img: g8,
    mobileimg: g8,
  },
  {
    id: 18,
    img: g9,
    mobileimg: g9,
  },
  {
    id: 19,
    img: g10,
    mobileimg: g10,
  },
  {
    id: 20,
    img: g11,
    mobileimg: g11,
  },
  {
    id: 21,
    img: g12,
    mobileimg: g12,
  },
  {
    id: 22,
    img: g12,
    mobileimg: g13,
  },
  // {
  //   id: 23,
  //   img: g13,
  //   mobileimg: g13,
  // },
  {
    id: 24,
    img: g14,
    mobileimg: g14,
  },
  // {
  //   id: 25,
  //   img: g15,
  //   mobileimg: g15,
  // },
  {
    id: 26,
    img: g16,
    mobileimg: g16,
  },
  {
    id: 27,
    img: g17,
    mobileimg: g17,
  },
  {
    id: 28,
    img: g18,
    mobileimg: g18,
  },
  {
    id: 29,
    img: g19,
    mobileimg: g19,
  },
  {
    id: 30,
    img: g20,
    mobileimg: g20,
  },
];

const testimonialData = [
  {
    id: 1,
    name: "Pragya Singh",
    position: "Principal",
    institute_name: "Composite Vidyalaya Kalyanpur",
    video: "https://prod-api.brainhap.com/files/media/2022/06/03/pragya.mp4",
    icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_1.png",
    icon2: teacherpic,
    icon3: teacherpicmob,
    text: "The students gave the test with a lot of enthusiasm",
    rating: 4,
    order: 1,
  },
  {
    id: 2,
    name: "Archana Dwivedi",
    position: "Principal",
    institute_name: "Ups Kanyavidyalay kanpur",
    video:
      "https://prod-api.brainhap.com/files/media/2022/06/03/WhatsApp_Video_2022-05-18_at_1.31.46_PM.mp4",
    icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_2.png",
    icon2: teacherpic,
    icon3: teacherpicmob,
    text: "The test is a great initiative for the students' benefit",
    rating: 5,
    order: 1,
  },
  {
    id: 3,
    name: "Sachin sir",
    position: "Principal",
    institute_name: "Chinchavali English Mumbai school",
    video: "https://prod-api.brainhap.com/files/media/2022/06/03/sachin.mp4",
    icon: "https://prod-api.brainhap.com/files/media/2022/06/03/Sachin_Sir_mhFN1Jx.png",
    icon2: teacherpic,
    icon3: teacherpicmob,
    text: "The test is a great opportunity for students today as there are many career options but no guidance from anyone",
    rating: 4,
    order: 1,
  },
  // {
  //   id: 4,
  //   name: "Susma Srivastav",
  //   position: "Principal",
  //   institute_name: "New World Public School",
  //   video:
  //     "https://prod-api.brainhap.com/files/media/2022/07/22/WhatsApp_Video_2022-07-20_at_1.18.05_PM.mp4",
  //   icon: "",
  //   icon2: teacherpic,
  //   icon3: teacherpicmob,
  //   text: "The test is a great initiative for the students' benefit",
  //   rating: 4,
  //   order: 1,
  // },
];

// const exploreobje = {
//   imgOne: StemAccredition,
//   textOne: "Stem Accredition   |",
//   imgTwo: iitbombey,
//   textTwo: "IITBOMBAY",
// };
const exploreobje = {
  imgOne: iitbombey,
  textOne: "IIT BOMBAY ",
  imgTwo: StemAccredition,
  textTwo: "STEM.ORG ",
};

const demoobj = {
  imgOne: StemAccredition,
  textOne: "Curriculum design you can trust",
};

const bannerdata = [
  {
    id: 1,
    img: bannerone,
    imgmobile: bannermobile,
    headingtext:
      " Introducing Revolutionary & India’s Own Brain Mapping Technology",
    bannerpara: "Empowering young individuals to achieve excellence with",
    highlight: "Neurotechnology",
  },
  {
    id: 2,
    img: bannertwo,
    imgmobile: bannermobile,
    headingtext: "EEG Headset to map brain activity in key learning areas",
    bannerpara: "Offering guidance to students on their career path progress.",
    highlight: "Neurotechnology",
  },
  {
    id: 3,
    img: banner3,
    imgmobile: bannermobile,
    headingtext:
      " Our dedicated years of research to revolutionize Career Counselling and Skill Development",
    bannerpara:
      " Through cutting-edge BrainMapping Technology, developed in association with IIT Bombay.",
    highlight: "Neurotechnology",
  },
  {
    id: 4,
    img: banner4,
    imgmobile: bannermobile,
    headingtext:
      " Our skill development educational courses hold accreditation from STEM.ORG ",
    bannerpara:
      "We have created our course content, keeping the NEP2020 & NCF 2023 Framework in focus.",
    highlight: "Neurotechnology",
  },
  {
    id: 5,
    img: bannerimg,
    imgmobile: bannermobile,
    headingtext:
      " Introducing India’s Innovative Neuroscience-based BrainMapping Technology in Education ",
    bannerpara:
      "Designed to identify a student’s interests, skills, and aptitudes with their academic performance to find most suitable ",
    highlight: "career path.",
  },
];
