import React from 'react'
import styles from "./index.module.css"

function EvoCardWWD(cardData) {

    console.log(cardData,"cardData");
  return (
    <div className={styles.CardBox} style={{background:cardData?.cardData?.backgroundCol}}>
        <div className={styles.cardimgcontainer}>
            <img src={cardData?.cardData?.img} alt='' />
        </div>
        <p className={styles.cardPara}>{cardData?.cardData?.para}</p>
        <h6 className={styles.cardNumber}>{cardData?.cardData?.id}</h6>
    </div>
  )
}

export default EvoCardWWD