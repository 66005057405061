import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsShieldCheck } from "react-icons/bs";
import { withRouter, Link } from "react-router-dom";
// import styles from "../../Pages/BrainHap/index.module.css";
import styles from "../../../Pages/BrainHap/index.module.css"
// import { Redirect } from 'react-router'
import blueBackground from "../../../Assets/brainhap/blueBackground.png";

import "./newCarousel.css";

class NewReviewsCarousel extends Component {
  myfunc(url) {
    let urls = url.split("#");
    console.log(urls, "urls");
    this.props.history.push(urls[1]);
  }
  render() {
    console.log(this.props.history, "carasol");
    const { sqd } = this.props;
    console.log("knknm", sqd);

    var settings = {
      className: "",
      dots: true,
      // autoplay: true,
      // infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      appendDots: (dots) => (
        <div>
          <ul> {dots} </ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{
            borderBottom: "4px solid #12888A66 ",
            backgroundColor: "#12888A66",
            // padding: "0px 80px",
          }}
          className="update-slick"
        ></div>
      ),
    };
    return (
      <>
        <Slider {...settings}>
          {sqd[0].map((x, i) => {
            return (
              <>
                <div key={i} style={{padding:0}} className="support_main testimonialCard removeMarginBottom">
                  <div className="box-inner">
                    <div className="testimonial_info">
                      <div
                        className="greyesh"
                        style={
                          {
                            // marginLeft: "20px",
                          }
                        }
                      >
                        <p className={styles.mbZTitle}>{x.name}</p>
                        <p className={styles.mbZ}>
                          {x.position}
                          <br />
                          <span style={{ fontWeight: "500", margin: "0" }}>
                            {x.institute_name}
                          </span>
                        </p>
                      </div>
                    </div>

                    {x.text && (
                      <p
                        style={{ position: "static" }}
                        className={`${styles.greye_sh} ${styles.testi_para} ${styles.improve_description}`}
                      >
                        {x.text}
                      </p>
                    )}
                  </div>

                  <div className="new-carousel-container hide-mobile">
                    {/* <img src={x?.icon3} alt="" /> */}
                    <video width="100%" className={styles.video_height} controls>
                    <source src={x.video} type="video/mp4" />
                  </video> 
                  </div>

                  <div className="new-carousel-container hide-pc  border_zero">
                    {/* <img src={x?.icon3} alt="" /> */}
                    <video width="100%" className={styles.video_height} controls>
                    <source src={x.video} type="video/mp4" />
                  </video> 
                  </div>

                  {/* <div className="testimonial_container">
                    <img
                      className="testimonial_image_back"
                      alt=""
                      src={blueBackground}
                      style={{
                        zIndex: "0",
                        position: "absolute",
                      }}
                    />
                    {!x.video ? (
                      <img
                        className="testimonial_image"
                        alt=""
                        src={x.icon}
                        
                      />
                    ) : (
                      <div style={{paddinTop:'5%'}} >
                        <video
                         
                          className="testimonial_image"
                          controls
                        >
                          <source src={x.video} type="video/mp4" />
                        </video>
                      </div>
                    )}
                  </div> */}
                </div>
              </>
            );
          })}
        </Slider>
        {/* <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="box-inner">
            <div
              style={{
                borderLeft: "3px solid #158F92",
                marginLeft: "-20px",
                paddingLeft: "20px",

                borderHeight: "100px",
              }}
            >
              <div
                className={styles.greyesh}
                style={
                  {
                    // marginLeft: "20px",
                  }
                }
              >
                <p className={styles.mbZTitle}>{"name"}</p>
                <p className={styles.mbZ}>
                  {"position"}
                  <br />
                  <span style={{ fontWeight: "500", margin: "0" }}>
                    {"institute_name"}
                  </span>
                </p>
              </div>
            </div>

            <p
              style={{ position: "relative" }}
              className={`${styles.greye_sh} ${styles.testi_para}`}
            >
              {"text"}
            </p>
          </div>

          <div className="">
            <img
              className={`${styles.subscribeImg}`}
              alt=""
              src={blueBackground}
            />
            {/* <video width="30%" className={styles.video_height} controls>
                    <source src={x.video} type="video/mp4" />
                  </video> * / }
          </div>
        </div> */}
      </>
    );
  }
}

export default withRouter(NewReviewsCarousel);
