import React from 'react'
import styles from './index.module.css'
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import certify from "../../Assets/newevopage/certi-img.png"
import iitbombey from "../../Assets/iitbombeylogo.png"

function StemAccreditionPage() {
  return (
    <>
    <section className={styles.mainSection}>
        {/* <div className={styles.backArrownav}>
            <ArrowBack />
        </div> */}

        <div className={styles.MainImageContainer}>
            <img src={certify} alt='' />
        </div>
    </section>
    <section className={styles.showNameSection}>
        <div className={styles.mainshow}>
            <h4 className={styles.textone}>Stem Accredition   |</h4>
            <div className={styles.logoimgcontainer}>
                <img src={iitbombey} alt='' />
            </div>
            <h4 className={styles.textone}> IITBOMBAY</h4>
        </div>
    </section>
    </>
  )
}

export default StemAccreditionPage